import * as React from "react"
import { Link } from "gatsby"
import Menu from "./menu"
import "../../assets/sass/example/example3.scss";
import { StaticImage } from "gatsby-plugin-image";
import SmoothScrollContainer from "../../components/SmoothScrollContainer";
import AnimTitle from "../../components/AnimTitle";

import {
  useViewportScroll,
  motion,
  useTransform,
  useMotionValue
} from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import FramerParallax from "../../components/FramerParallax";
import WSParallax from "../../components/WSParallax";


// markup
const Example3 = () => {

  const { scrollY } = useViewportScroll();
  const y1 = useTransform(scrollY, [0, 300], [0, -400]);
  const y2 = useTransform(scrollY, [0, 300], [0, -100]);

  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0.5,
    triggerOnce: false
  });

  console.log(entry);

  const variants = {
    visible: { opacity: 1, scale: 1, y: 0 },
    hidden: {
      opacity: 0,
      scale: 0.65,
      y: 50
    }
  };

  return (
      
      
    <main >
      <Menu></Menu>
      
      <section className="project-header">
        <div className="container">
          <div className="columns">
              <div className="column is-8 desc">
                <AnimTitle><p className="project-name">Healthy Eats</p></AnimTitle>
                <AnimTitle><p>Hassle-free Nutrition solution </p></AnimTitle>
                <AnimTitle><p>for busy professionals</p></AnimTitle>
              </div>
              <div className="column is-4 serv">
                  <ul>
                    <li>Branding</li>
                    <li>Responsive Web Design</li>
                    <li>Packaging</li>
                  </ul>
              </div>
          </div>
        </div>
    </section>
      
    <section className="hero main-hero">
        <WSParallax inputYmax={500} outputYmax={600} className="motionWrapper h1" xvalue={0.35}>
        <StaticImage className="bg-img" src="../../images/example/hero@2x.png"></StaticImage>
        </WSParallax>
    </section>

    <section id="logo-section">
      <div className="container">
        <div className="columns is-multiline">
              <div className="column imgbox is-6">
                <StaticImage src="../../images/example/logo.png"></StaticImage>
              </div>
              <div className="column imgbox is-6">
                <StaticImage src="../../images/example/van.png"></StaticImage>
              </div>
              <div className="column is-6">
                  <div className="textbox">
                  <p className="title">Hassle-free Nutrition solution for busy professionals</p>
                  <p className="desc">Fresh, delicious, ready to eat meals delivered to your doorstep. Fresh, delicious, ready to eat meals delivered to your doorstep.</p>
                  </div>
              </div>
              <div className="column imgbox is-6 no-padding">
                <StaticImage src="../../images/example/box.png"></StaticImage>
              </div>
        </div>
      </div>
    </section>


    <section id="menu-section">
      <div className="container">
        <div className="columns is-multiline">
              <div className="column imgbox is-8">
                <StaticImage src="../../images/example/menu.png"></StaticImage>
              </div>
              <div className="column is-4">
                  <div className="textbox center">
                  <p className="title">Hassle-free Nutrition solution for busy professionals</p>
                  <p className="desc">Fresh, delicious, ready to eat meals delivered to your doorstep. Fresh, delicious, ready to eat meals delivered to your doorstep.</p>
                  </div>
              </div>
        </div>
      </div>
    </section>

    <section className="hero cuberto-test">
        <WSParallax inputYmax={500} outputYmax={600} className="motionWrapper h2" xvalue={0.85}>
          <StaticImage className="bg-img" src="../../images/example/cuberto-cover.jpeg"></StaticImage>
        </WSParallax>
    </section>

    <section id="vid-section">
      <div className="container">
        <div className="columns is-multiline">
              <div className="column is-4">
                  <div className="textbox center">
                  <p className="title">Hassle-free Nutrition solution for busy professionals</p>
                  <p className="desc">Fresh, delicious, ready to eat meals delivered to your doorstep. Fresh, delicious, ready to eat meals delivered to your doorstep.</p>
                  </div>
              </div>
              <div className="column imgbox is-8">
                <div className="vid-showcase">
                  <StaticImage className="one" src="../../images/example/vid2.png"></StaticImage>
                  <StaticImage className="two" src="../../images/example/vid1.png"></StaticImage>
                  <StaticImage className="three" src="../../images/example/vid3.png"></StaticImage>
                </div>
              </div>
        </div>
      </div>
    </section>


    <section id="feat-section">
      <div className="container">
        <div className="columns is-multiline">
              <div className="column imgbox is-8">
                <StaticImage src="../../images/example/features.png"></StaticImage>
              </div>
              <div className="column is-4">
                  <div className="textbox center">
                  <p className="title">Hassle-free Nutrition solution for busy professionals</p>
                  <p className="desc">Fresh, delicious, ready to eat meals delivered to your doorstep. Fresh, delicious, ready to eat meals delivered to your doorstep.</p>
                  </div>
              </div>
        </div>
      </div>
    </section>

    <section className="hero">
        <div className="hero-body  footer-hero">
        </div>
    </section>

    </main>
  )
}

export default Example3
