import React, { useRef, useState, useEffect, useLayoutEffect } from 'react'
import { useInView } from "react-intersection-observer";
import { motion, useAnimation} from 'framer-motion'


const AnimTitle = ({ children, className }) => {

   
    const {ref, inView } = useInView();
    const titleAnimation = useAnimation();
   
      //performed after dom is mutated
      useLayoutEffect(() => {

        if(inView){
            console.log("Yes in view!")
            /*titleAnimation.start({
                opacity : 1,
                translateY : "0%",
                rotateX: 0,
                transition : { type : "spring", duration: 1}
            })*/
            titleAnimation.start("animate");
        }

      },[titleAnimation, inView]);

      const childVariants = {
          initial : {
            opacity :0.05, translateY: "127%", rotateX: -40,
            style:{ translate: "(0px,130%)" }
          },
          animate:{
                opacity : 1,
                translateY : "0%",
                rotateX: 0,
                transition : { type : "spring", duration: 1}
            }
      };

      const parentVariant = {
          initial : {},
          animate : { 
                transition: {
                    staggerChildren: 0.08,
                }
            }
      };

    return (
    
          <motion.div
          ref={ref}
          variants={parentVariant}
          animate={titleAnimation}
          className={className}
            /*initial= {{ opacity :0.05, translateY: "127%", rotateX: -40,
                style:{ translate: "(0px,130%)" }
            }}*/
          >
            {
            React.Children.map(children, (child, i) => {
                    return <motion.div key={i} variants={childVariants}>{child}</motion.div>
                })
            }
          </motion.div>
    );
}

export default AnimTitle